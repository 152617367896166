export default defineNuxtRouteMiddleware(async (to, from) => {
  const profileStore = useProfileStore();
  const { getSessionToken } = profileStore;

  if (getSessionToken()) return;

  if (profileStore.isLoggedIn) {
    profileStore.removeSession();
  }

  return navigateTo({
    path: from.fullPath || '/',
    query: { ...from.query, 'sign-in': 'true' },
  });
});
